@import "./bgt-variables.scss";

/* fonts */

@font-face {
  font-family: "Spartan MB";
  src:
    url("assets/fonts/SpartanMB-ExtraBold.woff2") format("woff2"),
    url("assets/fonts/SpartanMB-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Spartan MB";
  src:
    url("assets/fonts/SpartanMB-Regular.woff2") format("woff2"),
    url("assets/fonts/SpartanMB-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: "Spartan MB", sans-serif;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  color: $primary;
}

.mat-typography {
  letter-spacing: -0.02em !important;
}

.mat-typography h1, .mat-typography h2, .mat-typography h3, .mat-typography h4 {
  letter-spacing: -0.02em !important;
}

.mdc-form-field {
  letter-spacing: var(--mdc-typography-body2-letter-spacing, -0.02em) !important;
}

app-map {
  display: flex;
  height: 93.6%;
  height: calc(100% - 60px);
}

/* input placeholder */

::placeholder {
  color: #333;
  opacity: 1;
  font-family: "Spartan MB", sans-serif !important;
  font-size: 11px !important;
}

:-ms-input-placeholder {
  color: #333;
  font-family: "Spartan MB", sans-serif !important;
  font-size: 11px !important;
}

::-ms-input-placeholder {
  color: #333;
  font-family: "Spartan MB", sans-serif !important;
  font-size: 11px !important;
}

@media (min-width: 530px) {
  ::placeholder {
    font-size: 13px !important;
  }

  :-ms-input-placeholder {
    font-size: 13px !important;
  }

  ::-ms-input-placeholder {
    font-size: 13px !important;
  }
}

/* scrollbar */

body {
  scrollbar-arrow-color: rgb(196, 196, 196);
  scrollbar-track-color: rgb(250, 250, 250);
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: #79899c;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #5c6979;
}

::-webkit-scrollbar-track {
  background-color: rgba(206, 206, 206, 0.4);
}

/* disable blue Chrome focus */

*:focus {
  outline: 0;
}

/* disable ligatures */

* {
  font-variant-ligatures: none !important;
}

/* header and menu */

.header__buttons button {
  padding: 0 10px;
  color: $text;
}

.mat-mdc-menu-content a {
  text-decoration: none;
}

.mat-mdc-menu-item {
  color: $text !important;
}

/* material overrules sidebar */

.mdc-switch {
  margin-right: 5px !important;
}

.mat-mdc-slide-toggle label {
  position: relative;
  top: -1px;
  text-transform: capitalize !important;
  font-size: 13px !important;
}

.sidebar .mat-expansion-panel {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.sidebar .mat-expansion-panel-header {
  padding: 0 15px !important;
  font-size: 14px !important;
}

.mat-expansion-panel-header-title {
  top: -1px;
  position: relative;
}

.mat-expansion-indicator {
  top: -3px;
  position: relative;
}

.mat-expanded .mat-expansion-indicator {
  top: 3px;
}

.mat-expansion-panel-header.Transport {
  background: $transport !important;
}

.mat-expansion-panel-header.Bouwwerken {
  background: $bouwwerken !important;
}

.mat-expansion-panel-header.Vegetatie {
  background: $vegetatie !important;
}

.mat-expansion-panel-header.Inrichting {
  background: $inrichting !important;
}

.mat-expansion-panel-header.Overig {
  background: $overig !important;
}

.sidebar .mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-slide-toggle-bar {
  background: #eee !important;
}

/* material button */

.mdc-button {
  letter-spacing: -0.02em !important;
}

.mdc-button .mat-icon {
  position: relative;
  top: 1px;
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base {
  height: 44px !important;
}

.header .mat-mdc-button.mat-mdc-button-base,
.header .mat-mdc-raised-button.mat-mdc-button-base {
  height: 36px !important;
}

/* material override pages */

.page .mat-expansion-panel-header {
  font-size: 15px;
}

.page .mat-expansion-panel-header:hover {
  background: none !important;
}

/* material overrides modal */

.bgt-dialog .mat-dialog-container {
  padding: 0 !important;
}

.bgt-dialog .mat-dialog-actions {
  padding: 0 !important;
}

.bgt-dialog h3 {
  background: $primary;
  color: $text;
  padding: 15px 20px 12px;
  margin: 0 !important;
  font-size: 16px;
  position: relative;
}

.bgt-dialog p {
  margin: 0 !important;
  padding: 20px;
  font-size: 14px;
  color: $text;
}

.bgt-dialog .mat-icon {
  position: absolute;
  right: 14px;
  top: 15px;
  cursor: pointer;
}

.bgt-dialog button {
  margin: 0 20px 20px;
}

/* material overrides form */

.mat-form-field-label {
  color: $text !important;
  font-size: 15px !important;
}

.mat-input-element {
  line-height: 1em !important;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  -webkit-transform: translateY(-1.69373em) scale(0.65) !important;
  transform: translateY(-1.69373em) scale(0.65) !important;
}

.mat-error {
  color: red !important;
  font-size: 10px;
}

/* material overrides tabs */

.mdc-tab__text-label {
  letter-spacing: 0;
}

/* material overrides extra pages */

.grid-column .mat-grid-tile .mat-figure {
  flex-direction: column;
  align-items: unset !important;
}

html {
  --mdc-checkbox-state-layer-size: 20px;
}

.mat-mdc-checkbox .mdc-form-field {
  align-items: start;
}

.mdc-checkbox {
  top: 2px;
}

.mat-mdc-checkbox label {
  margin-left: 4px;
}

/* material overrides bottom sheet */

.mat-bottom-sheet-container {
  padding: 0 !important;
}

.mat-bottom-sheet-container .mat-nav-list {
  padding-top: 0px !important;
}

/* mat grid tile fix */

body .mat-grid-tile-content {
  display: block;
}

/* spinner */

.lds-dual-ring:after {
  border-color: $text transparent transparent transparent !important;
}

/* leaflet */

.leaflet-container {
  font-family: "Spartan MB", sans-serif;
}

.leaflet-tooltip {
  font-family: "Spartan MB", sans-serif;
}

.leaflet-tooltip:first-letter {
  text-transform: capitalize;
}

/* geocoder */

angular-geocoder {
  z-index: 900;
  position: relative;
  display: block;
}

@media (min-width: 530px) {
  #map .geocoder {
    width: inherit !important;
    margin: 10px;
  }
}

@media (min-width: 800px) {
  #map .geocoder {
    width: 376px !important;
  }
}

/* sub page styling */

.subpage {
  background: #f7f7f7;
  padding: 25px 0;
  height: 93.5%;
  height: calc(100% - 60px);
  overflow-y: auto;
}

.subpage__content {
  width: 600px;
  margin: 0 10px;
  max-width: 95%;
  max-width: calc(100% - 20px);
  background: #fff;
  box-sizing: border-box;
  border: 1px solid #eee;
}

@media (min-width: 620px) {
  .subpage__content {
    margin: 0 auto;
  }
}

.subpage__header {
  background: $primary;
  padding: 40px 40px 32px;
  text-align: center;
}

.subpage__header .mat-icon {
  font-size: 40px;
  width: auto;
  height: auto;
  color: $text;
}

.subpage__title {
  margin: 0 !important;
  color: $text;
}

.subpage__subtitle {
  padding: 0 20px;
}

.subpage__text {
  color: $text;
  font-size: 14px;
  line-height: 21px;
  padding: 0 20px 20px;
  margin: 0 !important;
}

.subpage__text:first-of-type {
  padding: 20px;
}

.subpage__text span {
  color: $darker-primary;
}

.subpage__link {
  margin: 0 0 0 20px !important;
  color: $primary;
  text-decoration: underline;
  cursor: pointer;
}

.subpage__button {
  margin: 0 0 20px 20px !important;
}

.subpage__form {
  padding: 0 20px;
}

.subpage__form__full {
  width: 100%;
}

.subpage__form .subpage__button {
  margin: 0 0 20px 0 !important;
}

/* pricing */

.pricing .mdc-tab-indicator__content {
  border-color: $primary;
}

.pricing .mdc-tab {
  background: #f7f7f7;
}

.pricing .mdc-tab--active {
  background: #fff;
}

/* inverse inforow pages */

.inverse .inforow {
  flex-direction: column-reverse !important;
}

@media (min-width: 981px) {
  .inverse .inforow {
    flex-direction: row-reverse !important;
  }
}

.inverse .inforow__right img {
  margin: 0 0 50px !important;
}

@media (min-width: 981px) {
  .inverse .inforow__right img {
    margin: 0 150px 0 0 !important;
  }
}

.inverse .inforow__right {
  text-align: center !important;
}

@media (min-width: 981px) {
  .inverse .inforow__right {
    text-align: left !important;
  }
}

.searchButton {
  padding: 10px 15px 11px 10px !important;
}

